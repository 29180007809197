import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import ContextConsumer from "src/layouts/Context.js"
import BodyText from "src/components/global/typography/BodyText.js"
import BigText from "src/components/global/typography/BigText.js"
import BigTextH3 from "src/components/global/typography/BigTextH3.js"

import distances from "src/assets/styles/distances.js"
import Wrapper from "src/components/global/Wrapper"
import Divider from "src/components/global/Divider.js"
import SmallTitle from "src/components/global/typography/smallTitle.js"
import CountUp from "react-countup"
import slugify from "slugify"
import Img from "gatsby-image"

const StyledWrapper = styled(Wrapper)`
  margin-top: ${props => props.marginTop}px !important;
`

const SectionWrapper = styled.div`
  position: relative;
  /* margin-top: 150px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${colors.white};
  }
`

const ProjectImage = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  height: 100%;
  transition: all 0.3s ease;
`

const ProjectWrapper = styled(Link)`
  width: calc(50% - 10px);
  display: none;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 40px;
  border-top: 2px solid ${colors.darkGrey};
  text-decoration: none;
  opacity: 1;
  &.onCompetance {
    display: flex;
  }
  &.active {
    display: flex;
  }
  /* Use nth-child for first two items */
  &:nth-child(-n + 2) {
    border-top: none;
  }

  /* Use nth-last-child for desktop */
  &:nth-last-child(-n + 2) {
    border-bottom: 2px solid ${colors.darkGrey};
  }

  &:hover {
    ${ProjectImage} {
      transform: translate(10px, -10px);
      transition: all 0.3s ease;
    }
  }

  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    border-bottom: none !important;

    /* Only last child gets border on mobile */
    border-top: 2px solid ${colors.darkGrey} !important;
    &:first-child {
      border-top: none !important;
    }

    &:last-child {
      border-bottom: 2px solid ${colors.darkGrey} !important;
    }
  }
`

const ProjectImageWrapper = styled.div`
  width: 83.33%;
  position: relative;
  margin-top: 40px;
  margin-bottom: 20px;
  height: 25vw;
  background-color: ${colors.mainColor};

  @media (max-width: ${mediaQuery.tablet}) {
    height: 50vw;
  }
`

const CompetencesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Competence = styled.span`
  font-size: ${typographySizes.s}px;
  color: ${colors.darkGrey};
  line-height: 1.5;

  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.sm}px;
  }

  span {
    margin: 0 10px;
  }
`

const StyledBigTextH3 = styled(BigTextH3)`
  height: 200px;
  @media (max-width: ${mediaQuery.tablet}) {
    height: 100px;
  }
`

const Project = ({ project, isLastTwo, active, clientsPage, locale }) => {
  let isActive = active === "all"
  console.log(active)
  if (!isActive) {
    isActive = project.node.competences.some(
      document => document.Name === active
    )
  }

  if (!clientsPage) {
    isActive = true
  }

  return (
    <ProjectWrapper
      to={`/${locale === "en" ? "en/projects" : "corobimy"}/${slugify(
        project.node.Title,
        {
          lower: true,
          strict: true,
        }
      )}`}
      isLastTwo={isLastTwo}
      className={isActive ? "active" : ""}
    >
      <div>
        <ProjectImageWrapper>
          <ProjectImage
            fluid={project.node.Thumbnail.localFile.childImageSharp.fluid}
          />
        </ProjectImageWrapper>
        <StyledBigTextH3>{project.node.Title}</StyledBigTextH3>
      </div>
      <CompetencesWrapper>
        {project.node.competences.map((competence, index) => (
          <Competence key={index}>
            {competence.Name}
            {index !== project.node.competences.length - 1 && <span>/</span>}
          </Competence>
        ))}
      </CompetencesWrapper>
    </ProjectWrapper>
  )
}

class Projects extends React.Component {
  render() {
    return (
      <StyledWrapper marginTop={this.props.marginTop}>
        {!this.props.clientsPage && <SmallTitle>{this.props.title}</SmallTitle>}

        <SectionWrapper>
          {this.props.projects.map((project, index) => (
            <Project
              clientsPage={this.props.clientsPage}
              active={this.props.active}
              key={index}
              project={project}
              isLastTwo={index >= this.props.projects.length - 2}
              locale={this.props.locale}
            />
          ))}
        </SectionWrapper>
      </StyledWrapper>
    )
  }
}

export default Projects
