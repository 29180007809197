import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import ContextConsumer from "src/layouts/Context.js"
import SEO from "src/components/global/SEO.js"
import distances from "src/assets/styles/distances.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import noHangers from "src/components/global/fn/noHangers.js"
import Navigation from "src/components/global/Navigation.js"
import Footer from "src/components/global/Footer"
import NavCompetence from "src/components/whatWeDo/NavCompetence.js"
import NavProjects from "src/components/whatWeDo/NavProjects.js"
import ProjectCard from "src/components/whatWeDo/ProjectCard.js"
import Wrapper from "src/components/global/Wrapper"
// import Crop from "src/components/global/Crop"
import ReactMarkdown from "react-markdown"
import CompetencesList from "src/components/global/competences/CompetencesList.js"
import colors from "src/assets/styles/colors.js"
import BigText from "src/components/global/typography/BigText.js"

import MainBtn from "src/components/global/btn/MainBtn"
import SmallTitle from "src/components/global/typography/smallTitle.js"
import MidText from "src/components/global/typography/MidText"
import BodyText from "src/components/global/typography/BodyText.js"

import Projects from "src/components/global/Projects.js"
import { useLocation } from "react-router-dom"

const CompetenceWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  @media (max-width: ${mediaQuery.tablet}) {
  }
`

const CompetenceContentWrapper = styled.div`
  width: 75%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`

const MainTitle = styled(BigText)`
  margin-bottom: 40px;
  width: 75%;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 20px;
  }
`

const SubTitle = styled(BodyText)`
  margin-left: 50%;
  margin-bottom: 80px;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 0%;
  }
`

const CompetenceFirstP = styled(MidText)`
  margin-bottom: ${distances.mainBigMargin}px;
  .marginP {
    p {
      margin-bottom: 20px;
    }
  }
`

const CompetenceSecondP = styled(BodyText)`
  width: 77.77%;
  .marginP {
    p {
      margin-bottom: 20px;
    }
    p:last-of-type {
      margin-bottom: 0;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`

const StyledWrapper = styled(Wrapper)`
  margin-bottom: 0px !important;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
  }
`

const TopTitle = styled(BigText)`
  margin-bottom: 20px;
`
const TopParagraph = styled(MidText)`
  margin-bottom: 100px;
  margin-top: 20px;
`
const Clients = ({ data, pageContext, location }) => {
  // const [num, setNum] = useQueryParam("index", NumberParam)
  const newData = data
  return (
    <>
      <SEO
        title={data.strapiClientsPage.SEO?.SEO_Title}
        description={data.strapiClientsPage.SEO?.SEO_Description}
        image={data.strapiClientsPage.SEO?.Seo_Img?.localFile?.publicURL}
      />

      <Navigation
        lang={pageContext.locale}
        langUrl={pageContext.langUrl}
        navBtnLink={data.strapiGlobal.Nav_btn_link}
        navBtnText={data.strapiGlobal.Nav_btn_text}
      />
      <StyledWrapper>
        {console.log(data)}
        <TopTitle>{data.strapiClientsPage.Title}</TopTitle>
        <TopParagraph>{data.strapiClientsPage.Subtitle}</TopParagraph>
        <NavProjects lang={pageContext.locale} />
      </StyledWrapper>
      <ContextConsumer>
        {({ data, set }) => (
          <Projects
            marginTop={0}
            clientsPage={true}
            title="test"
            active={data.projectCategory}
            projects={newData.allStrapiProjects.edges}
            locale={pageContext.locale}
          />
        )}
      </ContextConsumer>
      <Footer lang={pageContext.locale} companyData={data.strapiContactPageN} />
    </>
  )
  // }
}

export const query = graphql`
  query Clients($locale: String!) {
    strapiGlobal(locale: { eq: $locale }) {
      Nav_btn_link
      Nav_btn_text
    }
    strapiClientsPage(locale: { eq: $locale }) {
      locale
      Title
      Subtitle
      SEO {
        SEO_Title
        SEO_Description
        Seo_Img {
          localFile {
            publicURL
          }
        }
      }
    }
    allStrapiProjects(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          Title
          Thumbnail {
            localFile {
              childImageSharp {
                fluid(traceSVG: { background: "#ffffff", color: "#ffff00" }) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          competences {
            Name
          }
        }
      }
    }
    strapiContactPageN(locale: { eq: $locale }) {
      Company_details_Address
      Company_details_Krs
      Company_details_Name
      Company_details_Nip
      Company_details_Regon
      Company_details_Title
    }
  }
`

export default Clients
